// When the user scrolls down 50px from the top of the document, resize the header's font size
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    const header = document.getElementById("header");
    const scrollSize = 100;

    if (document.body.scrollTop > scrollSize || document.documentElement.scrollTop > scrollSize) {
        header.setAttribute('data-header', 'scrolled');
    } else {
        header.removeAttribute('data-header', 'scrolled');
    }
}

window.onload = function() {addNavSpacer()};

function addNavSpacer() {

    const pageHeader = document.getElementById('header');
    const wrapper = document.getElementById('wrapper');

    let headerHeight = pageHeader.offsetHeight;
    wrapper.setAttribute('style', 'padding-block-start:' + headerHeight + 'px;');
}


const toggle = document.querySelector('[data-nav-toggler]');

// console.log('el: ' + toggle);

