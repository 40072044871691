// toggles navi main

const toggle = document.getElementById("nav-toggler");
const navMobile = document.querySelector('#nav-main');

const navMobileActive = 'nav-mobile-active';
const isActive = 'is-active';

// console.log('log: ' + toggle);

toggle.addEventListener('click', navStatus);

function navStatus() {

    if (document.body.classList.contains(navMobileActive)) {
        navClose();
    }
    else {
        navOpen();
    }
}

function navClose() {
    document.body.classList.remove(navMobileActive);
    toggle.classList.remove(isActive);
    // navMobile.classList.remove(isActive);
}

function navOpen() {
    document.body.classList.add(navMobileActive);
    toggle.classList.add(isActive);
    // navMobile.classList.add(isActive);
}
